import React from 'react'
import { useInView } from 'react-intersection-observer'
import Intro from '../../animations/Intro'
import './style.scss'
import { graphql, useStaticQuery } from 'gatsby'

function SocialLinks(props) {
  const [ro, roInView] = useInView({ triggerOnce: true })

  const queryData = useStaticQuery(graphql`
		query {
      pdfSponsorDeck: file(relativePath: {eq: "IrieWeekend_SponsorDeck_2024.pdf" }) {
        publicURL
      }
		}
	`)

  const pdfSponsorDeck = queryData?.pdfSponsorDeck?.publicURL

  const data = {
    icons: [
      {
        icon: 'instagram',
        link: 'https://instagram.com/irieweekend/'
      },
      {
        icon: 'facebook',
        link: 'https://www.facebook.com/irieweekend/'
      },
      {
        icon: 'youtube',
        link: 'https://youtube.com/irieweekendTV/'
      },
      {
        icon: 'twitter',
        link: 'https://twitter.com/irieweekend/'
      },
      {
        icon: 'sponsorship',
        link: pdfSponsorDeck
      },
      // {
      // 	icon: 'discord',
      // 	link: '#'
      // },
    ]
  }


  const icons = data?.icons?.map((node, i) => {

    let icon = node.icon === 'facebook' ? 'FaceBook' :
      node.icon === 'twitter' ? 'Twitter' :
        node.icon === 'youtube' ? 'YouTube' :
          node.icon === 'instagram' ? 'Instagram' :
            node.icon === 'git' ? 'Git' :
              node.icon === 'sponsorship' ? 'Sponsorship' :
              null
    return i === 0 ? (
      <Intro key={i} visible={roInView} in={{ fadeRight: 700 }} delayIn={400 + 150 * i} mounted={true} stay={true} className="overflow-visible">
        <div className={`social-link`}>
          <a href={node.link} target='_blank' rel='noopener noreferrer'>
            {icon}
          </a>
        </div>
      </Intro>
    ) : (
      <Intro key={i} visible={roInView} in={{ fadeRight: 700 }} delayIn={400 + 150 * i} mounted={true} stay={true} className="overflow-visible">
        <div className={`social-link`}>
          <a href={node.link} target='_blank' rel='noopener noreferrer'>
            {icon}
          </a>
        </div>
      </Intro>
    )
  })


  return (
    <div ref={ro} className={`social-links ${props.classes}`}>
      {icons}
    </div>
  )
}

export default SocialLinks